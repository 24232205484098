import { Box, Stack, Typography } from "@mui/material"
import Button from "components/ui/Button/Button"
import { RATING_STEP } from "./Rating"

interface RatingThankYouProps {
  setStep: (step: RATING_STEP) => void
}

const RatingThankYou = ({ setStep }: RatingThankYouProps) => {
  return (
    <Stack spacing='32px' height='100%' justifyContent='center'>
      <Box flex='1'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          backgroundColor: 'rgba(70, 150, 236, 0.1)',
          borderRadius: '12px',
        }}
      >
        <Typography variant='h1'>Спасибо за Ваш отзыв!</Typography>
        <Typography sx={{ color: 'rgba(17, 35, 48, 0.3)', fontWeight: 400, fontSize: '16px' }}>Вы можете продолжить оценку</Typography>
      </Box>
      <Stack spacing='10px'>
        <Button fullWidth variant='contained' onClick={() => setStep(RATING_STEP.EMPLOYEE)}>
          Оценить работу сотрудника
        </Button>
        <Button fullWidth variant='contained' onClick={() => setStep(RATING_STEP.DEPARTMENT)}>
          Оценить работу подразделения ДП
        </Button>
      </Stack>
    </Stack >
  )
}

export default RatingThankYou
