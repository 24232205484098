import { Checkbox } from "@mui/material"
import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import FontIcon from "components/ui/FontIcon"
import renderOption from "components/utils/render-option"
import _ from "lodash"
import { ResponsibleType } from "models/responsible.model"
import { useEffect } from "react"
import { useGetFilteredResponsibleMutation, useGetResponsibleDeptQuery } from "reducers/api/responsible.api"

const ResponsibleAutoComplete = ({ multiple, departments, ...rest }: AutoCompleteProps<ResponsibleType> & { departments?: boolean }) => {

  const [getFilteredResponsible, { data: responsibleData, isLoading: isResponsibleLoading }] = useGetFilteredResponsibleMutation()

  const { data: depts } = useGetResponsibleDeptQuery()

  // const departmentsData = useMemo(() => {
  //   const groups = new Set<string>()
  //   responsibleData?.content?.forEach(item => {
  //     groups.add(item.forGroup)
  //   })
  //   return Array.from(groups)
  // }, [responsibleData])

  useEffect(() => {
    getFilteredResponsible([])
  }, [])

  return (
    <AutoComplete<ResponsibleType | string>
      fullWidth
      clientSide
      data={departments ? _.uniq(depts) : responsibleData?.content}
      loading={isResponsibleLoading}
      optionLabel={departments ? undefined : 'fullName'}
      multiple={multiple}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && (
            <Checkbox
              icon={<FontIcon icon='check_box_outline_blank' sx={{ color: 'primary.text03' }} />}
              checkedIcon={<FontIcon icon='check_box' sx={{ color: 'primary.main' }} />}
              checked={selected}
              sx={{
                padding: 0
              }}
            />
          )}
          {renderOption(departments ? { label: option as string } : { label: `${(option as ResponsibleType)?.fullName}`, description: (option as ResponsibleType)?.department })}
        </li>
      )}
      getAdornment={!departments ? (option: ResponsibleType) => option?.department : undefined}
      {...rest}
    />
  )
}

export default ResponsibleAutoComplete
