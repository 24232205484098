import { AxiosResponse } from "axios"
import { MenuItemType } from "components/ui/MenuList/MenuList"
import { API_URL, CONTEXT_MENU_HEADER, CONTEXT_MENU_ITEMS, MANAGERS, USERS } from "config/constants"
import { useAppDispatch } from "config/store"
import NodeType, { DocumentNodeType } from "models/node.model"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router"
import { useLazyGetAttachmentQuery } from "reducers/api/attachment.api"
import {
  useGetFavoritesQuery,
  // useGetHomepageQuery, 
  useGetMeQuery, useRemoveFavoriteMutation, useRemoveQuickNodeMutation, useSetFavoriteMutation,
  // useSetHomepageMutation, 
  useSetQuickNodeMutation
} from "reducers/api/employee.api"
import { useCreateFolderNodeMutation, useRenameNodeMutation } from 'reducers/api/node.api'
import { setRenaming } from "reducers/node"
import { isEditable } from "utils/checkRight"
import useClipboard from "./useClipboard"
import useConfirm from "./useConfirm"

function useActions(node: NodeType = {}) {

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()

  const { data: meData } = useGetMeQuery()
  // const { data: homepageData } = useGetHomepageQuery(undefined, { skip: !node })
  const { data: favoritesData } = useGetFavoritesQuery(undefined, { skip: !node })

  const { cut, copy, archive, restore } = useClipboard(node)
  const confirmArchive = useConfirm()

  // const isHomepage = node?.id !== homepageData?.id
  const isFavorite = useMemo(() => favoritesData?.findIndex(x => x.id === node?.id) >= 0, [node, favoritesData])
  const isQuick = node?.isQuick

  // const [setHomepage] = useSetHomepageMutation()
  const [setFavorite] = useSetFavoriteMutation()
  const [removeFavorite] = useRemoveFavoriteMutation()
  const [setQuickNode] = useSetQuickNodeMutation()
  const [removeQuickNode] = useRemoveQuickNodeMutation()
  const [createFolderNode] = useCreateFolderNodeMutation()
  const [renameNode] = useRenameNodeMutation()
  const [getAttachment] = useLazyGetAttachmentQuery()

  const manageRelatedDocuments = () => navigate(`/settings/related-documents/${node?.id}`, {
    state: {
      background: location,
      nodeId: node ? node?.parent?.id || node?.id : node?.parent?.id || node?.id
    }
  })

  const manageTrends = (item: NodeType = node) => navigate(`/settings/trends${item ? `/${item?.id}` : ''}`, {
    state: {
      background: location,
      //nodeId: node ? node?.parent?.id || node?.id : node?.parent?.id || node?.id
    }
  })

  const manageResponsible = () => navigate(`/settings/responsible/${node?.id}`, {
    state: {
      background: location
    }
  })

  const manageNodeAccess = () => navigate(`/settings/node-rights/${node?.id}`, {
    state: {
      background: location
    }
  })

  // const handleSetHomepage = () => {
  //   setHomepage({ nodeId: node?.id })
  // }

  const handleToggleFavorites = () => {
    if (isFavorite)
      removeFavorite({ nodeId: node?.id })
    else
      setFavorite({ nodeId: node?.id })
  }

  const handleToggleQuickNode = () => {
    if (isQuick)
      removeQuickNode({ nodeId: node?.id })
    else
      setQuickNode({ nodeId: node?.id })
  }

  const open = (item: NodeType = node) => {
    if (item?.nodeType === 'DOCUMENT')
      navigate(`/document/${item?.id}`)
    else
      navigate(`/content/${item?.id}`)
  }

  const handleCreateFolderNode = async () => {
    const newNode = {
      parent: { id: node?.id },
      nodeType: 'FOLDER' as NodeType['nodeType'],
    }
    const response = await createFolderNode(newNode)
    if ((response as AxiosResponse<NodeType>)?.data)
      dispatch(setRenaming({ node: (response as AxiosResponse<NodeType>)?.data }))
  }

  const handleRenameNode = () => {
    dispatch(setRenaming({ node }))
  }

  const completeRenameNode = async (name?: string) => {
    if (name && name !== node?.name)
      await renameNode({ id: node?.id, name })
    dispatch(setRenaming())
  }

  const handleArchiveNode = () => {
    return confirmArchive({
      title: t('kbl.dialog.title.general.confirm'),
      acceptColor: 'error',
      message: t('kbl.dialog.message.archiveNode', { nodeName: node?.name }),
      onAccept: async () => { archive([node]) },
    })
  }

  const handleRestoreNode = () => {
    return confirmArchive({
      title: t('kbl.dialog.title.general.confirm'),
      message: t('kbl.dialog.message.restoreNode', { nodeName: node?.name }),
      onAccept: async () => { restore([node]) },
    })
  }

  const handleCutNode = () => cut([node])
  const handleCopyNode = () => copy([node])

  const getDownloadUrl = async (item: NodeType = node) => {
    const { data } = await getAttachment({ attachmentId: item.document?.attachment?.id })
    return data?.url
  }

  const getPreviewUrl = (item: NodeType = node) => {
    return API_URL + `/document/attachment/${item.document?.attachment?.id}/preview`
  }

  const download = async (item: DocumentNodeType = node) => {
    const url = await getDownloadUrl(item)
    if (url) {
      const link = document.createElement('a')
      link.download = 'filename'
      link.href = url
      link.click()
      return true
    }
    return false
  }

  const contextMenuHeader: { [key: string]: Omit<MenuItemType, 'key'> } = {
    [CONTEXT_MENU_HEADER.EXPANDER]: { expander: true },
    [CONTEXT_MENU_HEADER.CUT]: { icon: 'content_cut', command: handleCutNode },
    [CONTEXT_MENU_HEADER.COPY]: { icon: 'content_copy', command: handleCopyNode },
    [CONTEXT_MENU_HEADER.PASTE]: { icon: 'content_paste' },
    [CONTEXT_MENU_HEADER.DOWNLOAD]: { icon: 'download', command: download },
    [CONTEXT_MENU_HEADER.EDIT]: { icon: 'edit', command: handleRenameNode },
    [CONTEXT_MENU_HEADER.MANAGE_TRENDS]: { icon: 'edit', command: manageTrends },
    [CONTEXT_MENU_HEADER.DELETE]: { icon: 'delete', data: { sx: { color: 'primary.mosmetro' } }, command: handleArchiveNode },
    [CONTEXT_MENU_HEADER.RESTORE]: {
      icon: 'restore_from_trash',
      command: handleRestoreNode
    },
  }

  const contextMenuItems: { [key: string]: Omit<MenuItemType, 'key'> } = {

    // ...(isHomepage && { [CONTEXT_MENU_ITEMS.HOMEPAGE]: { label: t('kbl.context.items.setHomepage'), icon: 'home', command: handleSetHomepage } }),

    [CONTEXT_MENU_ITEMS.FAVORITE]: {
      label: isFavorite
        ? t('kbl.context.items.removeFavorite')
        : t('kbl.context.items.setFavorite'), icon: 'star', command: handleToggleFavorites
    },

    [CONTEXT_MENU_ITEMS.MANAGE_TRENDS]: { label: t('kbl.context.items.manageTrends'), icon: 'edit', command: manageTrends },

    [CONTEXT_MENU_ITEMS.QUICK_NODE]: {
      label: isQuick
        ? t('kbl.context.items.removeQuickNode')
        : t('kbl.context.items.setQuickNode'), icon: 'bolt', command: handleToggleQuickNode
    },

    [CONTEXT_MENU_ITEMS.OPEN]: {
      label: t('kbl.context.items.openNode', { nodeName: node?.name }),
      icon: 'folder_open',
      command: open
    },

    [CONTEXT_MENU_ITEMS.CREATE_FOLDER]: {
      label: t('kbl.context.items.createFolder'),
      icon: 'create_new_folder',
      command: handleCreateFolderNode
    },

    [CONTEXT_MENU_ITEMS.RESPONSIBLE]: {
      label: t('kbl.context.items.responsible', { nodeName: node?.name }),
      icon: 'group',
      command: manageResponsible
    },

    [CONTEXT_MENU_ITEMS.NODE_RIGHTS]: {
      label: t('kbl.context.items.nodeAccess', { nodeName: node?.name }),
      icon: 'groups',
      command: manageNodeAccess
    },

    [CONTEXT_MENU_ITEMS.RESTORE]: {
      label: t('kbl.context.items.restore', { nodeName: node?.name }),
      icon: 'restore_from_trash',
      command: handleRestoreNode
    },
  }

  const renderHeader = (headerItems: string[]) => headerItems
    ?.map((item, idx) => contextMenuHeader[item] ? { key: idx, ...contextMenuHeader[item] } : undefined)
    .filter(Boolean)

  const renderItems = (items: string[]) => items
    ?.map((item, idx) => contextMenuItems[item] ? { key: idx, ...contextMenuItems[item] } : undefined)
    .filter(Boolean)

  const getContextHeader = (item: NodeType = node) => {
    switch (item.nodeType) {
      case "TREND":
        return renderHeader([
          ...(MANAGERS.includes(meData?.role.recordType) ? [CONTEXT_MENU_HEADER.EXPANDER, CONTEXT_MENU_HEADER.MANAGE_TRENDS] : []),
        ])
      case "FOLDER":
        return renderHeader([
          ...(isEditable(item) ? [CONTEXT_MENU_HEADER.CUT] : []),
          ...(isEditable(item) ? [CONTEXT_MENU_HEADER.COPY] : []),
          ...(isEditable(item) ? [
            CONTEXT_MENU_HEADER.EXPANDER,
            item.isDeleted ? CONTEXT_MENU_HEADER.RESTORE : CONTEXT_MENU_HEADER.DELETE,
            CONTEXT_MENU_HEADER.EDIT] : []),
        ])
      case "DOCUMENT":
        return renderHeader([
          ...(isEditable(node) ? [CONTEXT_MENU_HEADER.CUT] : []),
          ...(isEditable(node) ? [CONTEXT_MENU_HEADER.COPY] : []),
          ...(isEditable(node) ? [
            CONTEXT_MENU_HEADER.EXPANDER,
            node.isDeleted ? CONTEXT_MENU_HEADER.RESTORE : CONTEXT_MENU_HEADER.DELETE,
            CONTEXT_MENU_HEADER.EDIT] : []),
        ])
      default:
        return []
    }
  }

  const getContextItems = (item: NodeType = node) => {
    switch (item.nodeType) {
      case "TREND":
        return renderItems([
          CONTEXT_MENU_ITEMS.OPEN,
          // CONTEXT_MENU_ITEMS.HOMEPAGE,
          CONTEXT_MENU_ITEMS.FAVORITE,
          CONTEXT_MENU_ITEMS.QUICK_NODE,
          ...(isEditable(node) ? [CONTEXT_MENU_ITEMS.RESPONSIBLE] : []),
          ...(isEditable(node) && !USERS.includes(meData?.role.recordType) ? [CONTEXT_MENU_ITEMS.NODE_RIGHTS] : []),
        ])
      case "FOLDER":
        return renderItems([
          CONTEXT_MENU_ITEMS.OPEN,
          // CONTEXT_MENU_ITEMS.HOMEPAGE,
          CONTEXT_MENU_ITEMS.FAVORITE,
          CONTEXT_MENU_ITEMS.QUICK_NODE,
          ...(isEditable(item) ? [CONTEXT_MENU_ITEMS.RESPONSIBLE] : []),
          ...(isEditable(item) ? [CONTEXT_MENU_ITEMS.NODE_RIGHTS] : []),
        ])
      case "DOCUMENT":
        return renderItems([
          CONTEXT_MENU_ITEMS.OPEN,
          CONTEXT_MENU_ITEMS.FAVORITE,
          CONTEXT_MENU_ITEMS.QUICK_NODE,
          ...(isEditable(node) ? [CONTEXT_MENU_ITEMS.RESPONSIBLE] : []),
          ...(isEditable(node) ? [CONTEXT_MENU_ITEMS.NODE_RIGHTS] : []),
        ])
      default:
        return []
    }
  }

  return {
    getContextHeader,
    getContextItems,
    renderHeader,
    renderItems,
    manageRelatedDocuments,
    manageResponsible,
    completeRenameNode,
    handleArchiveNode,
    handleRestoreNode,
    open,
    download,
    getPreviewUrl,
    handleToggleFavorites,
    handleCreateFolderNode
  }
}

export default useActions
