import { Stack } from "@mui/material"
import Button from "components/ui/Button/Button"
import { RATING_STEP } from "./Rating"

interface RatingStartProps {
  setStep: (step: RATING_STEP) => void
}

const RatingStart = ({ setStep }: RatingStartProps) => {
  return (
    <Stack spacing='10px' height='100%' justifyContent='center'>
      <Button fullWidth variant='contained' onClick={() => setStep(RATING_STEP.EMPLOYEE)}>
        Оценить работу сотрудника
      </Button>
      <Button fullWidth variant='contained' onClick={() => setStep(RATING_STEP.DEPARTMENT)}>
        Оценить работу подразделения ДП
      </Button>
    </Stack>
  )
}

export default RatingStart
