import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { QuizAnswersType, QuizManyType } from "models/quiz.model"

const apiUrl = `${API_URL}/feedback`

export const attachment = commonApi.injectEndpoints({
  endpoints: builder => ({

    getQuiz: builder.query<QuizManyType, { quizType: string }>({
      query: ({ quizType }) => ({
        url: `${apiUrl}/quiz-directory/by-type/${quizType}`,
      }),
    }),

    postQuiz: builder.mutation<QuizAnswersType, any>({
      query: (body) => ({
        url: `${apiUrl}/quiz`,
        method: 'POST',
        body,
      }),
    }),
  })
})

export const {
  useGetQuizQuery,
  usePostQuizMutation,
} = attachment

export default attachment
