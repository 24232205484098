import Button from 'components/ui/Button/Button'
import Dialog from 'components/ui/Dialog'
import { QuizAnswersType } from 'models/quiz.model'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router"
import { usePostQuizMutation } from 'reducers/api/quiz.api'
import Quiz from './Quiz'
import RatingStart from './Start'
import RatingThankYou from './Thankyou'

export enum RATING_STEP {
  START = 'start',
  EMPLOYEE = 'employee',
  DEPARTMENT = 'department',
  THANKYOU = 'thankyou'
}

interface RatingProps {
  callbackUrl: string
}

const Rating = ({ callbackUrl }: RatingProps) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [step, setStep] = useState(RATING_STEP.START)
  const [answers, setAnswers] = useState<QuizAnswersType>()

  const [postQuiz] = usePostQuizMutation()

  const handleClose = () => navigate(callbackUrl)

  const handleChange = (data: QuizAnswersType) => { setAnswers(data) }

  const handleCancel = () => {
    setAnswers(null)
    setStep(RATING_STEP.START)
  }

  const handleConfirm = () => {
    postQuiz(answers)
    setStep(RATING_STEP.THANKYOU)
  }

  const isDisabled = !answers?.assessed || answers?.answers.length === 0

  const stepContentMap = {
    [RATING_STEP.START]: <RatingStart setStep={setStep} />,
    [RATING_STEP.EMPLOYEE]: <Quiz quizType='WORKER' onChange={handleChange} />,
    [RATING_STEP.DEPARTMENT]: <Quiz quizType='SUBDIVISION' onChange={handleChange} />,
    [RATING_STEP.THANKYOU]: <RatingThankYou setStep={setStep} />,
  }

  const stepActionsMap = {
    [RATING_STEP.START]: (
      <Button fullWidth variant='outlined' onClick={handleClose}>
        {t('kbl.buttons.close')}
      </Button>
    ),

    [RATING_STEP.EMPLOYEE]: (
      <>
        <Button fullWidth variant='outlined' onClick={handleCancel}>
          {t('kbl.buttons.cancel')}
        </Button>
        <Button fullWidth variant='contained' color='success' disabled={isDisabled} onClick={handleConfirm}>
          {t('kbl.buttons.send')}
        </Button>
      </>
    ),

    [RATING_STEP.DEPARTMENT]: (
      <>
        <Button fullWidth variant='outlined' onClick={handleCancel}>
          {t('kbl.buttons.cancel')}
        </Button>
        <Button fullWidth variant='contained' color='success' disabled={isDisabled} onClick={handleConfirm}>
          {t('kbl.buttons.send')}
        </Button>
      </>
    ),

    [RATING_STEP.THANKYOU]: (
      <Button fullWidth variant='outlined' onClick={handleClose}>
        {t('kbl.buttons.close')}
      </Button>
    ),
  }

  const stepTitleMap = {
    [RATING_STEP.START]: 'Оценка работы юристов',
    [RATING_STEP.EMPLOYEE]: 'Оценка работы сотрудника',
    [RATING_STEP.DEPARTMENT]: 'Оценка работы подразделения ДП',
    [RATING_STEP.THANKYOU]: 'Оценка работы юристов',
  }

  return (
    <Dialog
      fullHeight
      open={true}
      title={stepTitleMap[step]}
      onClose={handleClose}
      actions={stepActionsMap[step]}
    >
      {stepContentMap[step]}
    </Dialog>
  )
}

export default Rating
