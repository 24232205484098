import { FormControl, FormControlLabel, MenuItem, Stack, Switch, TextField } from "@mui/material"
import PasswordField from "components/ui/PasswordField"
import { ADMINS } from "config/constants"
import { useEffect, useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UserType } from "../../../../models/user.model"
import { useGetRolesQuery } from "../../../../reducers/api/users.api"

const UserManageForm = ({ employeeId }) => {
  const { t } = useTranslation()
  const { data: roleData } = useGetRolesQuery()

  const { register, control, setValue, getValues, watch, formState: { errors } } = useFormContext<UserType>()

  const roles = useMemo(() => {
    return (
      roleData?.content?.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      )
      ))
  }, [roleData])

  useEffect(() => {
    const role = roleData?.content.find(x => x.id === getValues('role')?.id)
    setValue('isAdmin', ADMINS.includes(role?.recordType))
  }, [roleData, getValues('role')])

  watch()

  return (
    <FormControl>
      <Stack spacing='16px'>
        <TextField {...register('firstName', { required: true })} InputLabelProps={{ shrink: getValues('firstName') ? true : false }} label={t('kbl.dataGrid.firstName')} />
        <TextField {...register('middleName', { required: true })} InputLabelProps={{ shrink: getValues('middleName') ? true : false }} label={t('kbl.dataGrid.middleName')} />
        <TextField {...register('lastName', { required: true })} InputLabelProps={{ shrink: getValues('lastName') ? true : false }} label={t('kbl.dataGrid.lastName')} />
        <Controller
          name='role'
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <TextField
              select
              label={t('kbl.dataGrid.role')}
              value={value?.id || ''}
              onChange={e => onChange({ id: e.target.value })}
            >
              {roles || []}
            </TextField>
          )}
        />
        <Controller
          render={({ field: { name, value, onChange } }) => (
            <TextField
              autoComplete='new-email'
              name={name}
              value={value}
              onChange={onChange}
              label={t('kbl.labels.email')}
              error={Boolean(errors[name])}
              helperText={errors[name] ? t('kbl.invalidateMessage.InvalidEmailFormat') : ''}
              disabled={employeeId !== '-'}
            />
          )}
          control={control}
          name="email"
          defaultValue=""
          rules={{ required: true }}
        />
        <Controller
          name='password'
          control={control}
          rules={{ required: employeeId === '-' ? true : false }}
          render={({ field: { onChange } }) => (
            <PasswordField autoComplete='new-password' label={t('kbl.labels.password')} onChange={onChange} />
          )}
        />
        {employeeId !== '-' && <Controller
          name='isActive'
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel control={<Switch checked={value || false} onChange={onChange} />}
              label={t('kbl.labels.isUserActive')}
              sx={{ width: 'fit-content', '&:hover': { color: 'primary.main' } }}
            />
          )}
        />}
      </Stack>
    </FormControl>
  )
}

export default UserManageForm
