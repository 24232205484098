import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { ResponsibleManyType, ResponsibleType } from "models/responsible.model"
import { convertToPatch } from "modules/Main/Content/ResponsibleReference/ResponsibleDialog/utils"
import { extraNames } from "./utils"

const apiUrl = `${API_URL}/kbl/responsible`

export const responsible = commonApi.injectEndpoints({
	endpoints: builder => ({

		getFilteredResponsible: builder.mutation<ResponsibleManyType, any>({
			query: (body) => ({
				method: 'POST',
				url: `${apiUrl}/filtering?size=500`,
				body,
			}),
			transformResponse: (result: ResponsibleManyType) => ({
				...result, content: result.content.map((item: ResponsibleType) => ({
					...item,
					...extraNames(item)
				}))
			})
		}),

		getResponsibleDept: builder.query<any, void>({
			query: () => ({
				method: 'GET',
				url: `${apiUrl}/for_group_and_department_list`,
			}),
		}),

		getResponsible: builder.query<ResponsibleType, { id: string | number }>({
			query: ({ id }) => ({
				url: `${apiUrl}/${id}`,
			}),
			transformResponse: (result: ResponsibleType) => ({
				...result,
				...extraNames(result)
			}),
			providesTags: (result) => result ? [{ type: 'RESPONSIBLE', id: result.id }] : [],
		}),

		removeResponsible: builder.mutation<any, { id: string }>({
			query: (body) => ({
				method: 'POST',
				url: `${apiUrl}/archive`,
				body
			}),
			invalidatesTags: (result) => result ? [{ type: 'RESPONSIBLE', id: 'LIST' }] : [],
		}),

		createResponsible: builder.mutation<any, ResponsibleType>({
			query: (body) => ({
				method: 'POST',
				url: `${apiUrl}`,
				body
			}),
			invalidatesTags: (result) => result ? [{ type: 'RESPONSIBLE', id: 'LIST' }] : [],
		}),

		updateResponsible: builder.mutation<any, ResponsibleType>({
			query: ({ id, ...rest }) => {
				return ({
					method: "PATCH",
					headers: {
						'content-type': 'application/json-patch+json',
					},
					url: `${apiUrl}/${id}`,
					body: convertToPatch(rest)
				})
			},
			invalidatesTags: (result, _, arg) => result ? [{ type: 'RESPONSIBLE', id: arg.id }, { type: 'RESPONSIBLE', id: 'LIST' }] : [],
		}),

		updateResponsibleNode: builder.mutation<any, { id: string, nodeId: string }>({
			query: ({ id, nodeId }) => {
				return ({
					method: "POST",
					url: `${apiUrl}/${id}/node`,
					body: { id: nodeId }
				})
			},
			invalidatesTags: (result, _, arg) => result ? [{ type: 'RESPONSIBLE', id: arg.id }, { type: 'RESPONSIBLE', id: 'LIST' }] : [],
		}),

		removeResponsibleNode: builder.mutation<any, { id: string, nodeId: string }>({
			query: ({ id, nodeId }) => {
				return ({
					method: "POST",
					url: `${apiUrl}/${id}/node/remove`,
					body: { id: nodeId }
				})
			},
			invalidatesTags: (result, _, arg) => result ? [{ type: 'RESPONSIBLE', id: 'LIST' }, { type: 'RESPONSIBLE', id: arg.id }] : [],
		}),

	})
})

export const {
	useGetResponsibleQuery,
	useGetResponsibleDeptQuery,
	useGetFilteredResponsibleMutation,
	useUpdateResponsibleMutation,
	useCreateResponsibleMutation,
	useRemoveResponsibleMutation,
	useUpdateResponsibleNodeMutation,
	useRemoveResponsibleNodeMutation
} = responsible

export default responsible
