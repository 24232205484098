import { Experimental_CssVarsProvider as CssVarsProvider, StyledEngineProvider } from '@mui/material'
import { REFRESH_TOKEN } from 'config/constants'
import { useAppDispatch, useAppSelector } from 'config/store'
import { ConfirmDialogProvider } from 'hooks/useConfirm'
import Login from 'modules/Login'
import Main from 'modules/Main/Main'
import AccessGroups from 'modules/Settings/AccessGroups'
import NodeResponsible from 'modules/Settings/NodeResponsible'
import NodeRights from 'modules/Settings/NodeRights'
import RelatedDocuments from 'modules/Settings/RelatedDocuments/RelatedDocuments'
import Trends from 'modules/Settings/Trends'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRefreshMutation } from 'reducers/api/auth.api'
// import { useGetHomepageQuery } from 'reducers/api/employee.api'
import Rating from 'modules/Rating/Rating'
import { login, logout } from 'reducers/auth'
import { setContextId } from 'reducers/context'
import { PrivateRoute } from 'routes'
import theme from 'theme'
import Users from "../../modules/Settings/Users/Users"
import '../../utils/muiLicense'
import styles from './app.module.scss'

function App() {

  const { t } = useTranslation()

  const location = useLocation()
  // const navigate = useNavigate()
  let background = location.state?.background

  const dispatch = useAppDispatch()
  const logged = useAppSelector(state => state.auth.logged)
  const expiresIn = useAppSelector(state => state.auth.expiresIn)
  const refreshToken = localStorage.getItem(REFRESH_TOKEN)
  const [refresh, refreshResponse] = useRefreshMutation()

  // const { data: homepageData } = useGetHomepageQuery(undefined, { skip: !logged })

  // открывать модалки по прямому урлу
  if (!background && location.pathname.includes('/settings/trends'))
    // background = homepageData ? `/content/${homepageData.id}` : '/'
    background = '/'

  if (!background && location.pathname.includes('/settings/users'))
    // background = homepageData ? `/content/${homepageData.id}` : '/'
    background = '/'

  if (!background && location.pathname.includes('/settings/access-groups'))
    // background = homepageData ? `/content/${homepageData.id}` : '/'
    background = '/'

  if (!background && location.pathname.includes('/settings/responsible'))
    background = { pathname: `/content/${location.pathname.split('/')?.[3]}` }

  if (!background && location.pathname.includes('/settings/node-rights'))
    background = { pathname: `/content/${location.pathname.split('/')?.[3]}` }

  if (!background && location.pathname.includes('/settings/related-documents'))
    background = { pathname: `/document/${location.pathname.split('/')?.[3]}` }

  if (!background && location.pathname.includes('/rating'))
    background = '/'

  const handleContextMenuClick = () => {
    dispatch(setContextId(undefined))
  }

  useEffect(() => {

    let refreshInterval: any

    // if (logged && location.pathname === '/' && homepageData?.id) {
    //   navigate(`/content/${homepageData.id}`)
    // }

    switch (logged) {
      case true:
        if (expiresIn)
          refreshInterval = setInterval(() => refresh(refreshToken), expiresIn / 2)
        break
      case undefined:
        if (refreshToken)
          refresh(refreshToken)
        else
          dispatch(logout())
    }

    return () => { clearInterval(refreshInterval) }

  }, [logged, expiresIn,
    // homepageData, 
    refreshToken])

  useEffect(() => {
    if (refreshResponse?.data) {
      dispatch(login(refreshResponse?.data))
    }
    if (refreshResponse?.error) {
      dispatch(logout())
    }
  }, [refreshResponse])

  useEffect(() => {
    console.log('app mounted')
    document.addEventListener('contextmenu', handleContextMenuClick)
    return () => {
      document.removeEventListener('contextmenu', handleContextMenuClick)
    }
  }, [])

  return (
    <>
      <StyledEngineProvider injectFirst>
        <CssVarsProvider theme={theme}>
          <ToastContainer
            position={toast.POSITION.TOP_LEFT}
            className='toastify-container'
            toastClassName='toastify-toast'
          />
          {logged !== undefined ? (
            <div className={styles['app-container']}>
              <div className={styles['app-content']}>
                <Routes location={background || location}>
                  <Route path='/login' element={<Login callbackUrl={location.state?.callbackUrl} />} />
                  <Route
                    path='/*'
                    element={
                      <PrivateRoute isAllowed={logged}>
                        <ConfirmDialogProvider>
                          <Main />
                        </ConfirmDialogProvider>
                      </PrivateRoute>
                    }
                  />
                </Routes>
                {background && (
                  <Routes>
                    <Route path="/settings/trends/:id?" element={<Trends draggable={true} callbackUrl={background || location} />} />
                    <Route path="/settings/users/:employeeId?" element={<Users callbackUrl={background || location} />} />
                    <Route path="/settings/access-groups/:accessGroupId?" element={<AccessGroups callbackUrl={background || location} />} />
                    <Route path="/settings/responsible/:id?" element={<NodeResponsible callbackUrl={background || location} />} />
                    <Route path="/settings/node-rights/:id?" element={<NodeRights callbackUrl={background || location} />} />
                    <Route path="/settings/related-documents/:nodeId?" element={<RelatedDocuments callbackUrl={background || location} />} />
                    <Route path="/rating" element={<Rating callbackUrl={background || location} />} />
                  </Routes>
                )}
              </div>
            </div>
          ) : (
            <>{t('kbl.loading')}</>
          )}
        </CssVarsProvider>
      </StyledEngineProvider>
    </>
  )
}

export default App
