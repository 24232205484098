import { Box, Rating, Stack, TextField, Typography } from "@mui/material"
import { QuestionProps, QuestionsGroupProps } from "./types"

const Question = ({ id, name, answerType, order, version, onAnswer }: QuestionProps) => {

  const handleChange = (answer: string) => {
    onAnswer(id, {
      order,
      version,
      id,
      answer
    })
  }

  return (
    <Box
      className='option'
      component='form'
      display='flex'
      alignItems='center'
      padding={answerType === 'STRING' ? 0 : '12px 16px 12px 16px'}
    >
      {answerType === 'STRING' ? (
        <TextField
          multiline
          rows={4}
          InputProps={{
            sx: { height: 'auto' },
          }}
          sx={{
            width: '100%',
            'textarea': {
              paddingBlock: '8px',
            },
          }}
          onChange={(event) => handleChange(event.target.value)}
        />
      )
        : (
          <Stack direction='row' flex='1' justifyContent='space-between'>
            <Typography color='rgba(17, 35, 48, 0.5)'>{name}</Typography>
            <Rating onChange={(_, value) => handleChange(value?.toString())} />
          </Stack>
        )
      }
    </Box>
  )
}

const Questions = ({ questions, onAnswer }: Pick<QuestionsGroupProps, 'questions' | 'onAnswer'>) => {
  return (
    <Stack
      sx={{
        border: '1px solid rgba(17, 35, 48, 0.1)',
        borderRadius: '6px',
        '& .MuiBox-root.option:not(:last-of-type)': {
          borderBottom: '1px solid rgba(17, 35, 48, 0.1)',
        }
      }}
    >
      {questions.map(question => <Question key={question.id} {...question} onAnswer={onAnswer} />)}
    </Stack>
  )
}

const QuestionsGroup = ({ group, questions, onAnswer }: QuestionsGroupProps) => {
  return (
    <Stack spacing='8px'>
      <Typography variant='h2'>{group}</Typography>
      <Questions questions={questions} onAnswer={onAnswer} />
    </Stack>
  )
}

export { QuestionsGroup }
