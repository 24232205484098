import ContextMenu from "components/ui/ContextMenu"
import TreeItem from "components/ui/Tree/TreeItem"
import { TreeItemProps } from "components/ui/Tree/TreeItem/TreeItem"
import { CONTEXT_MENU_ITEMS, MANAGERS, USERS } from "config/constants"
import useActions from "hooks/useActions"
import { useMemo, useState } from "react"
import { useGetMeQuery } from "reducers/api/employee.api"
import { isEditable } from "utils/checkRight"
import SidebarTreeSubItem from "./SidebarTreeSubItem"

function SidebarTreeItem(props: TreeItemProps) {

  const { node: item, setItem, selected: initialSelected, ...rest } = props
  const { data: meData } = useGetMeQuery()

  const contextItems = useMemo(() => [
    // CONTEXT_MENU_ITEMS.HOMEPAGE,
    CONTEXT_MENU_ITEMS.FAVORITE,
    ...(MANAGERS.includes(meData?.role.recordType) ? [CONTEXT_MENU_ITEMS.MANAGE_TRENDS] : []),
    ...(isEditable(item) ? [CONTEXT_MENU_ITEMS.RESPONSIBLE] : []),
    ...(isEditable(item) && !USERS.includes(meData?.role.recordType) ? [CONTEXT_MENU_ITEMS.NODE_RIGHTS] : []),
  ], [item])

  const { renderItems } = useActions(item)

  const [selected, setSelected] = useState(false)

  const treeItem = useMemo(() => setItem(item), [setItem, item])

  return (
    <ContextMenu
      contextId={`sidebar-item-${item.id}`}
      items={renderItems(contextItems)}
      onOpen={() => { setSelected(true) }}
      onClose={() => { setSelected(false) }}
    >
      <TreeItem
        component={SidebarTreeSubItem}
        node={item}
        item={treeItem}
        setItem={setItem}
        selected={selected || initialSelected}
        {...rest}
      />
    </ContextMenu>
  )
}

export default SidebarTreeItem
